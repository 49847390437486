import React from 'react'
// graphql
import { useQuery } from '@apollo/client';
import { GET_PAGE_DATA, GET_ALL_EVENTS } from '../../graphql/queries';
// pages
import Error from '../Error';
// helmet
import { Helmet } from 'react-helmet';
// components
import Loading from '../../components/Elements/Loading';
import Event from '../../components/Basics/Event';
import Redirect from '../../components/Basics/Redirect';
import Button from '../../components/Basics/Button';

export default function Evenementen() {
  const slug = 'evenementen';

  // --------- QUERY FOR THE PAGE DATA --------- //

  const { loading, error, data } = useQuery(GET_PAGE_DATA, {
    variables: { slug },
  });
  const { loading: loadingEvents, error: errorEvents, data: dataEvents } = useQuery(GET_ALL_EVENTS);
  if (loading || loadingEvents) return <Loading />;
  if (error || errorEvents) return <Error code='502' text='Invalid response. Please try again later.'/>;

  // --------- PAGE DATA --------- //

  const pageData = data.page;
  const events = dataEvents.events;

  // --------- RENDER --------- //

  return (
    <>
      {/* head data */}
      <Helmet>
        <title>{pageData.title}</title>
        <meta name='description' content={pageData.pageDescription}/>
      </Helmet>

      <div className='container container--top'>
        <h1 className='container__header'>Evenementen</h1>
        <div className='container__header'>
          <Button label="Activiteiten kalender 2024 - 2025" link="/assets/documents/affiche_kalender24_25.pdf" target="_blank" />
          <Redirect link='https://www.facebook.com/p/RFC-Doggen-100057054506591/' label='Alle evenementen op de Facebook pagina' target='_blank' />
        </div>
        <div className='events'>
          {events.map((event, index) => {
            return event.image?.url ? <Event key={index} image={event.image.url} title={event.title} date={event.dateDescription} description={event.description}/> : <Event key={index} title={event.title} date={event.dateDescription} description={event.description}/>;
          })}
        </div>
      </div>
    </>
  )
}
